import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Menu from './components/Menu';
import Option from './components/Option';
import Invalid from './components/Invalid';
import Media from './components/Media';
import NotFound from './components/notFound';
import Login from './components/Login';

const AppRoutes = () => (
    <Routes>
        <Route path="/" element={<Home />} />

        <Route path="/index.html" element={<Home />} />

        <Route path='/login' element={<Login />} />

        <Route path="/home" element={<Home />} />

        <Route path="/menu/:id" element={<Menu />} />

        <Route path="option/:type/:id" element={<Option />} />

        <Route path="media/:id" element={<Media />} />

        <Route path="invalid" element={<Invalid />} />

        <Route path="*" element={<NotFound />} />
    </Routes>
);

export default AppRoutes;