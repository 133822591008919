
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './styles.css'

function Option() {

    const { type, id } = useParams();
    const [, setError] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [countdown, setCountdown] = useState(0);

    const handlePhotoClick = async () => {
        setIsLoading(true);
        let counter = 3;
        setCountdown(counter);

        const countdownInterval = setInterval(() => {
            counter -= 1;
            setCountdown(counter);
            if (counter <= 0) {
                clearInterval(countdownInterval);
            }
        }, 1000);

        setTimeout(async () => {
            try {
                // Send a WebSocket message here
                localStorage.removeItem("photos");
                localStorage.removeItem("photoname");
                const socket = new WebSocket(process.env.REACT_APP_MIDDLEWARE_URL, [process.env.REACT_APP_CLIENT_AUTH, process.env.REACT_APP_CLIENT_AUTH_SHARED_ID]);
                socket.addEventListener("open", (event) => {
                    socket.send(JSON.stringify({ "command": "TAKEPHOTO", "sessionCode": localStorage.getItem("token"), "recipient": "backend" }));
                });

                socket.addEventListener("message", (event) => {
                    try {
                        const jsonobj = JSON.parse(event.data);
                        if (jsonobj.message !== undefined) {
                            console.log("session expired")
                        }
                        if (jsonobj.photos !== undefined) {
                            const response = jsonobj.photos;
                            console.log(response, 'photo in option....');
                            setIsLoading(false);
                            socket.close();
                            navigate(`/media/${id}`, { state: { photos: response } });
                        }
                    } catch (error) {
                        console.error(error);
                        setIsLoading(false);
                        setError("Failed to take photos")
                    }
                });

                return () => {
                    socket.close();
                };

            } catch (error) {
                setIsLoading(false);
                console.error('Fout bij het verzenden van de foto-opties:', error);
            }
        }, 3000);
    };




    const handleGifClick = () => {

        setIsLoading(true);
        let counter = 3;
        setCountdown(counter);

        const countdownInterval = setInterval(() => {
            counter -= 1;
            setCountdown(counter);
            if (counter <= 0) {
                clearInterval(countdownInterval);
            }
        }, 1000);

        setTimeout(async () => {
            try {
                // Send a WebSocket message here
                localStorage.removeItem("photos");
                localStorage.removeItem("photoname");
                const socket = new WebSocket(process.env.REACT_APP_MIDDLEWARE_URL, [process.env.REACT_APP_CLIENT_AUTH, process.env.REACT_APP_CLIENT_AUTH_SHARED_ID]);
                socket.addEventListener("open", (event) => {
                    socket.send(JSON.stringify({ "command": "TAKEGIF", "sessionCode": localStorage.getItem("token"), "recipient": "backend" }));
                });

                socket.addEventListener("message", (event) => {
                    try {
                        const jsonobj = JSON.parse(event.data);
                        if (jsonobj.gifName !== undefined) {
                            const response = jsonobj.gifName;
                            setIsLoading(false);
                            socket.close();
                            navigate(`/media/${id}`, { state: { gif: response } });
                        }
                    } catch (error) {
                        console.error(error);
                        setIsLoading(false);
                        setError("Failed to take gif")
                    }
                });

                return () => {
                    socket.close();
                };

            } catch (error) {
                setIsLoading(false);
                console.error('Fout bij het verzenden van de foto-opties:', error);
            }
        }, 3000);

    };

    useEffect(() => {

    }, []);

    return (
        <div className='background'>
            <div className='flex_center'>
                {isLoading && <div className="text-center">
                    {countdown > 0
                        ? <p className='h1 mb-5'>{countdown}<br></br>Please look at the camera</p>
                        : <p className='h1 mb-5'>Please look at the camera</p>}
                    <div className="spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>}
                {!isLoading && (
                    <>
                        {type === 'photo' && (
                            <div className="text-center">
                                <p className='h3 mb-3'>3 photos will be taken of you</p>
                                <p className='h3 mb-5'>Are you ready?</p>
                                <button onClick={handlePhotoClick} className="start" >Start</button>
                            </div>
                        )}
                        {type === 'gif' && (
                            <div className="text-center">
                                <p className='h3 mb-3'>A gif will be taken of you for 4 seconds</p>
                                <p className='h3 mb-5'>Are you ready?</p>
                                <button onClick={handleGifClick} className="start" >Start</button>
                            </div>
                        )}
                    </>
                )}
                {!isLoading && <div className='position-fixed bottom-0 w-100 text-center mb-3'>
                    <a type="button" className="btn btn-secondary btn-lg" href={`/menu/${id}`} >Back</a>
                </div>}
            </div>
        </div>
    );

}


export default Option;