import React from 'react';

const NotFound = () => {

    return (
        <div className='background flex_center'>
            <section className="flex items-center h-screen p-16 bg-gray-50 dark:bg-gray-700">
                <div className="container flex flex-col items-center ">
                    <div className="flex flex-col gap-6 max-w-md text-center">
                        <h2 className="h1">
                            404
                        </h2>
                        <p className="text-2xl md:text-3xl dark:text-gray-300">Page Not Found !</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NotFound;
