import React from 'react';
import { useLocation } from 'react-router-dom';

const Invalid = () => {
    const location = useLocation();
    const message = location.state?.message;

    return (
        <div className='background'>
            <div className='flex_center'>
                {message && <h5 className='text-center'>{message}</h5>}
            </div>
        </div>
    );
};

export default Invalid;
