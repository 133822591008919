import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Media.css';

function Media() {
    const [error, setError] = useState('');
    const location = useLocation();
    const { photos = [] } = location.state || {};
    const { gif } = location.state || {};
    const [encodedPhotos, setEncodedPhotos] = useState('');
    const [isGif, setIsGif] = useState(false);
    const [loading, setLoading] = useState(true);
    const [printed, setPrinted] = useState(false);



    useEffect(() => {
        let storage = [];
        const storedPhotos = localStorage.getItem("photos");
        if (storedPhotos && storedPhotos !== "null") {
            const parsedPhotos = JSON.parse(storedPhotos);
            if (parsedPhotos && parsedPhotos.photos) {
                // console.log("Collected from local storage");
                setEncodedPhotos(parsedPhotos.photos);
                storage = parsedPhotos;
                setLoading(false);
            }
            if (parsedPhotos && parsedPhotos.gifName) {
                // console.log("Collected from local storage");
                setEncodedPhotos([parsedPhotos.gifName]);
                setIsGif(true);
                storage = parsedPhotos;
                setLoading(false);
            }

            if (localStorage.getItem("printed") === "true") {
                setPrinted(true);
            }
        }

        let photosTest = photos;

        if (gif !== undefined) {
            photosTest = [gif];
        }

        if (photosTest.length !== 0 && storage.length === 0) {
            try {
                const socket = new WebSocket(process.env.REACT_APP_MIDDLEWARE_URL, [process.env.REACT_APP_CLIENT_AUTH, process.env.REACT_APP_CLIENT_AUTH_SHARED_ID]);

                socket.addEventListener("open", (event) => {
                    if (gif) {
                        socket.send(JSON.stringify({ "command": "GETGIF", "gifName": gif, "recipient": "backend" }));
                    } else {
                        socket.send(JSON.stringify({ "command": "GETPHOTOS", "photos": photos, "recipient": "backend" }));
                    }
                });

                // Listen for messages
                socket.addEventListener("message", (event) => {
                    try {
                        const jsonobj = JSON.parse(event.data);

                        // console.log(event.data);
                        if (jsonobj.photos !== undefined) {
                            // console.log("Received photos");
                            setEncodedPhotos(jsonobj.photos);
                            localStorage.setItem("photos", event.data);
                            localStorage.setItem("photoname", photos);
                            socket.close();
                            setLoading(false);
                        }
                        if (jsonobj.gifName !== undefined) {
                            // console.log("Received photos");
                            setEncodedPhotos([jsonobj.gifName]);
                            localStorage.setItem("photos", event.data);
                            setIsGif(true);
                            socket.close();
                            setLoading(false);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                });
            } catch (error) {
                console.log(error);
            }
        } else if (storage.length === 0 && gif === undefined) {
            setError("Please take photos first");
        }

        // eslint-disable-next-line
    }, [error]);

    if (error) {
        return <div className='background flex_center'><div>{error}</div></div>;
    }

    const downloadPhoto = (url = "") => {
        url = url + '';
        const fileName = Math.floor(Math.random() * (Number.MAX_SAFE_INTEGER - 0));
        let ext = ".png";
        let type = "";

        switch (true) {
            case url.startsWith("data:image/gif"):
                type = 'image/gif';
                ext = ".gif";
                break;
            case url.startsWith("data:image/png"):
                type = 'image/png';
                ext = ".png";
                break;
            default:
                type = 'image/jpeg';
                ext = ".jpg";
                break;
        }

        console.log(window.isSecureContext);

        const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

        if (iOS && navigator.share) {
            // Share API is available, use it to prompt user to save image
            navigator.share({
                title: 'Save Image',
                text: 'Download your result',
                files: [new File([base64toBlob(url, type)], fileName + ext, { type: type })],
            }).then(() => {
                console.log('Image shared successfully');
            }).catch((error) => {
                console.error('Error sharing image:', error);
                // If sharing fails, fallback to download link method
                downloadWithLink(url, fileName, ext, type);
            });
        } else {
            // Fallback to download link method
            downloadWithLink(url, fileName, ext, type);
        }
    };

    const downloadWithLink = (url, fileName, ext, mimeType) => {
        const link = document.createElement('a');

        if (/^data:image\/(?:jpeg|png|gif)/.test(url)) {
            // For base64 images, create a Blob
            const blob = base64toBlob(url, mimeType);
            link.href = URL.createObjectURL(blob);
        } else {
            // For direct URLs, use the URL directly
            link.href = url;
        }

        link.download = fileName + ext;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const base64toBlob = (base64, mimeType) => {
        const byteCharacters = atob(base64.split(',')[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
    };



    const print = async () => {
        console.log('Printing is not yet implemented.');
        const socket = new WebSocket(process.env.REACT_APP_MIDDLEWARE_URL, [process.env.REACT_APP_CLIENT_AUTH, process.env.REACT_APP_CLIENT_AUTH_SHARED_ID]);

        socket.addEventListener("open", (event) => {
            socket.send(JSON.stringify({ "command": "PRINT", "sessionCode": localStorage.getItem("token"), "photos": localStorage.getItem("photoname"), "recipient": "backend" }));
        });

        socket.addEventListener("message", (event) => {
            const jsonobj = JSON.parse(event.data);
            if (jsonobj.sessionCode === localStorage.getItem("token") && jsonobj.printed) {
                localStorage.setItem("printed", "true");
                setPrinted(true);
                socket.close();
                alert("Successfully sent image to printer. Please wait...");
            }
        });
    };

    if (loading) {
        return <div className='background flex_center'>
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
        </div>;
    }

    return (

        <div className='background'>
            <div className='flex_center'>
                <div className="w-75">
                    {encodedPhotos && (
                        <div className='img_box'>
                            <img src={encodedPhotos} alt="Item" />
                        </div>
                    )}

                    {(encodedPhotos) && (
                        <div className="d-grid gap-2 col-md-4 mx-auto">
                            <button onClick={() => downloadPhoto(encodedPhotos)} className="btn btn-primary">
                                <i className="bi bi-download"></i> Download
                            </button>
                            {!isGif && !printed && (
                                <button onClick={print} className="btn btn-primary">
                                    <i className="bi bi-printer"></i> Print
                                </button>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Media;
