import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QRCode from "react-qr-code";
import { useWebSocket } from './WebsocketConnection';
import './styles.css'

function Home() {
    const code = new URLSearchParams(useLocation().search).get('code');
    const { ws, qrCode, error, validCode, token } = useWebSocket(code === null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const isPhotoBooth = code === null;

    const codeRef = useRef(null);

    useEffect(() => {
        codeRef.current = code;
    }, [code]);

    useEffect(() => {
        const fetchData = async () => {
            // Check if settings are set
            const qrcodeUsername = localStorage.getItem("qrcodeUsername");
            const qrcodePassword = localStorage.getItem("qrcodePassword");

            if (!qrcodeUsername && !qrcodePassword && isPhotoBooth) {
                navigate("/login");
                return;
            }

            if (codeRef.current !== null) {
                console.log("Is qr code exists");
                if (ws && ws.readyState === WebSocket.OPEN) {
                    console.log("Backend");
                    localStorage.removeItem("printed");
                    ws.send(JSON.stringify({ "recipient": "backend", "command": "LOGIN", code: codeRef.current }));
                }

                if (validCode === false) {
                    navigate("/invalid", { state: { message: "QR code invalid. Please scan the QR code again." } });
                }

                if (localStorage.getItem("token") === token) {
                    await ws.close();
                    navigate(`/menu/${codeRef.current}`);
                }
            } else {
                const sendQrCodeCommand = () => {
                    if (ws && ws.readyState === WebSocket.OPEN) {
                        ws.send(JSON.stringify({ "command": "QRCODE", "qrcodeUsername": qrcodeUsername, "qrcodePassword": qrcodePassword, "recipient": "backend" }));
                    }
                };

                if (qrCode !== "") {
                    setLoading(false);
                }

                const interval = setInterval(sendQrCodeCommand, 5000);

                return () => clearInterval(interval);
            }
        };

        fetchData();
    }, [validCode, qrCode, navigate, ws, token, isPhotoBooth]);

    if (loading) {
        return <div className='background flex_center'>
            <div className="spinner-border" role="status">
                <span className="sr-only"></span>
            </div>
        </div>;
    }

    if (error) {
        return <div className='background flex_center'><div>{error}</div></div>;
    }

    return (
        <div className="background">
            <div className='flex_center'>
                {!qrCode.includes("busy") && qrCode && (
                    <div className='border border-5 border-white rounded'>
                        <a target='_blank' href={qrCode} rel="noreferrer">
                            <QRCode

                                className='QR_code' value={qrCode} size={250} />
                        </a>
                    </div>
                )}
                {qrCode.includes("busy") && <div className="section  process flex_center">
                    <h2>Photo booth is busy</h2>
                    <svg className="cell-phone" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        width="177.5px" height="275.5px" viewBox="0 0 177.5 275.5" enableBackground="new 0 0 177.5 275.5" >
                        <g id="icon">
                            <path id="base" fill="#76ABAE" d="M143.6,256h-108c-8.8,0-16-7.2-16-16V32c0-8.8,7.2-16,16-16h108c8.8,0,16,7.2,16,16v208
                                                  C159.6,248.8,152.4,256,143.6,256z"/>
                            <rect id="screen" x="31.6" y="52" fill="#EEEEEE" width="116" height="168" />
                            <circle id="nav" fill="#FFFFFF" cx="89.199" cy="239.2" r="8" />
                            <path id="speaker" fill="#FFFFFF" d="M105.6,38h-32c-2,0-4-1.6-4-4l0,0c0-2,2-4,4-4h32c2.399,0,4,2,4,4l0,0
                                                     C109.6,36.4,107.999,38,105.6,38z"/>
                        </g>
                        <g className="scan">
                            <path d="M67.239,157.512v8.604h-8.604v-8.604H67.239z M67.239,105.884v8.605h-8.604v-8.605H67.239z M118.866,105.884v8.605h-8.604
                         v-8.605H118.866z M50.03,174.653h25.814v-25.747H50.03V174.653z M50.03,123.093h25.814V97.279H50.03V123.093z M101.657,123.093
                         h25.814V97.279h-25.814V123.093z M84.448,140.302v43.023H41.425v-43.023H84.448z M118.866,174.721v8.604h-8.604v-8.604H118.866z
                         M136.075,174.721v8.604h-8.604v-8.604H136.075z M136.075,140.302v25.814h-25.813v-8.604h-8.605v25.813h-8.604v-43.023h25.814
                         v8.604h8.604v-8.604H136.075z M84.448,88.675v43.023H41.425V88.675H84.448z M136.075,88.675v43.023H93.052V88.675H136.075z"/>
                            <line className="redline" fill="none" stroke="#C10000" strokeWidth="4" strokeMiterlimit="10" x1="36.425" y1="88.675" x2="141.759" y2="88.675" />
                        </g>
                        <g className="text">
                            <svg x="39px" y="0px" width="100.5px" height="275.5px" className='persons' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                <path d="M48 48h88c13.3 0 24-10.7 24-24s-10.7-24-24-24H32C14.3 0 0 14.3 0 32V136c0 13.3 10.7 24 24 24s24-10.7 24-24V48zM175.8 224a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-26.5 32C119.9 256 96 279.9 96 309.3c0 14.7 11.9 26.7 26.7 26.7h56.1c8-34.1 32.8-61.7 65.2-73.6c-7.5-4.1-16.2-6.4-25.3-6.4H149.3zm368 80c14.7 0 26.7-11.9 26.7-26.7c0-29.5-23.9-53.3-53.3-53.3H421.3c-9.2 0-17.8 2.3-25.3 6.4c32.4 11.9 57.2 39.5 65.2 73.6h56.1zm-89.4 0c-8.6-24.3-29.9-42.6-55.9-47c-3.9-.7-7.9-1-12-1H280c-4.1 0-8.1 .3-12 1c-26 4.4-47.3 22.7-55.9 47c-2.7 7.5-4.1 15.6-4.1 24c0 13.3 10.7 24 24 24H408c13.3 0 24-10.7 24-24c0-8.4-1.4-16.5-4.1-24zM464 224a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-80-32a64 64 0 1 0 -128 0 64 64 0 1 0 128 0zM504 48h88v88c0 13.3 10.7 24 24 24s24-10.7 24-24V32c0-17.7-14.3-32-32-32H504c-13.3 0-24 10.7-24 24s10.7 24 24 24zM48 464V376c0-13.3-10.7-24-24-24s-24 10.7-24 24V480c0 17.7 14.3 32 32 32H136c13.3 0 24-10.7 24-24s-10.7-24-24-24H48zm456 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H608c17.7 0 32-14.3 32-32V376c0-13.3-10.7-24-24-24s-24 10.7-24 24v88H504z" />
                            </svg>
                        </g>
                    </svg>
                </div>}
            </div>
        </div>
    );
}

export default Home;
