import { useState } from "react";
import { useNavigate } from 'react-router-dom';


function Login() {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleUsernameChange = (event) => {
        setUsername(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleLoginButton = (event) => {
        event.preventDefault();
        localStorage.setItem("qrcodeUsername", username);
        localStorage.setItem("qrcodePassword", password);
        navigate("/");
    };

    return (
        <div className="background">
            <div className='flex_center text-center '>
                <div className="border border-white border-opacity-25 rounded-4 shadow p-5">
                    <h2>Photo booth login</h2>
                    <form onSubmit={handleLoginButton}>
                        <div className="mb-3 mt-5">
                            <input id="username" type="text" className="login-input" placeholder="Username" onChange={handleUsernameChange}></input>
                        </div>
                        <div className="mb-5">
                            <input id="password" type="password" className="login-input" placeholder="Password" onChange={handlePasswordChange}></input>
                        </div>
                        {(username || password) ? <p></p> : null}
                        <div className="">
                            <button type="submit" className="login-input" disabled={!username || !password} >Login</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;