import { useState, useEffect } from "react";

export const useWebSocket = (isPhotoBooth = false) => {
    const [ws, setWs] = useState(null);
    const [qrCode, setQrCode] = useState('');
    const [error, setError] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
    const [token, setToken] = useState("");
    const [validCode, setValidCode] = useState(true);
    const [sessionExpired, setSessionExpired] = useState(false)

    useEffect(() => {
        const connectWebSocket = async () => {
            try {
                const url = process.env.REACT_APP_MIDDLEWARE_URL;

                let authHeader = [];

                if (isPhotoBooth) {
                    authHeader = [process.env.REACT_APP_PHOTO_BOOTH_AUTH, process.env.REACT_APP_CLIENT_AUTH_SHARED_ID];
                } else {
                    authHeader = [process.env.REACT_APP_CLIENT_AUTH, process.env.REACT_APP_CLIENT_AUTH_SHARED_ID];
                }

                const websocket = new WebSocket(url, authHeader);

                await new Promise((resolve, reject) => {
                    websocket.onopen = () => {
                        if (localStorage.getItem("qrcodeUsername") && localStorage.getItem("qrcodePassword")) {
                            websocket.send(JSON.stringify({ "command": "QRCODE", "qrcodeUsername": localStorage.getItem("qrcodeUsername"), "qrcodePassword": localStorage.getItem("qrcodePassword"), "recipient": "backend" }));
                        }
                        resolve();
                    };

                    websocket.onerror = (event) => {
                        setError(event.data);
                        reject(event.data);
                    };
                });

                websocket.onmessage = (event) => {
                    try {
                        console.log(event.data);
                        const parsedData = JSON.parse(event.data);

                        if (parsedData.code !== undefined) {
                            setQrCode(`${window.location.href}?code=${parsedData.code}`);
                            setError("");
                        }

                        if (parsedData.token !== undefined) {
                            setQrCode('');
                            setIsAuthenticated(true);
                            setToken(parsedData.token);
                            localStorage.setItem("token", parsedData.token);
                        }

                        if (parsedData.message === "invalid code") {
                            setValidCode(false);
                        }

                        if (parsedData.message === "session expired") {
                            setSessionExpired(true);
                        }

                        if (parsedData.code === "busy") {
                            setValidCode(false);
                        }

                        if (parsedData.code === "invalid") {
                            localStorage.clear();
                        }
                    } catch (error) {
                        console.error(error);
                    }
                };

                websocket.onclose = () => {
                    setError("Not connected to server");
                };

                setWs(websocket);
            } catch (error) {
                console.error("WebSocket connection error:", error);
                setError("WebSocket connection error");
            }
        };

        connectWebSocket();

        return () => {
            if (ws) {
                ws.close();
            }
        };
        // eslint-disable-next-line
    }, [isPhotoBooth]);

    return { ws, qrCode, error, validCode, isAuthenticated, token, sessionExpired };
};
