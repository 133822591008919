import '../App.css';
import React, { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useWebSocket } from './WebsocketConnection';

function Menu() {
    const navigate = useNavigate();
    const { id } = useParams();
    const { qrCode, isAuthenticated, ws, sessionExpired } = useWebSocket();

    const handleLogout = useCallback(() => {
        if (localStorage.getItem("token") !== null) {
            ws.send(JSON.stringify({ "command": "LOGOUT", "recipient": "backend" }));
        }
        localStorage.clear();
        navigate("/invalid", { state: { message: "Successfully logged out" } });
    }, [ws, navigate]);

    useEffect(() => {
        if (!qrCode.includes("busy") && qrCode !== "") {
            console.log(qrCode);
            handleLogout();
        }

        if (sessionExpired) {
            handleLogout();
        }

        return () => ws && ws.close();
    }, [isAuthenticated, navigate, qrCode, handleLogout, ws, sessionExpired]);

    const handleStateChange = (event) => {
        const newState = event.target.value;

        navigate(`/option/${newState}/${id}`);
    };




    return (
        <div className='background'>
            <div className='flex_center'>

                <input id="photo" className='mb-3' type="radio" name="options" value="photo" onChange={handleStateChange} />
                <label htmlFor="photo" >Photo</label>

                <input id="gif" type="radio" name="options" value="gif" onChange={handleStateChange} />
                <label htmlFor="gif" >Gif</label>

                <div className='position-fixed bottom-0 w-100 text-center mb-3'>
                    <button type="button" className="btn btn-secondary btn-lg" onClick={handleLogout}>Cancel</button>
                </div>
            </div>
        </div>
    );

}

export default Menu;